import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';
import {
  DatePicker,
  Select,
  Tooltip,
  MultiSelect,
  // Button,
} from 'sw-ui';

import { AutoUnloading1c } from './AutoUnloading1c';
import COMPONENTS from '../../bi/constants/components';
import AjaxButton from '../../components/ajaxButton';
import SelectWithInput from '../../components/SelectWithInput';

import { getMoment, prevMonthFirstDay, prevMonthLastDay } from '../../bi/utils/formatDate';
import { formatDate } from '../../utils/date';

import {
  ACCOUNTTEXT,
  COUNTERAGENT,
  DUKEMNT_NUMBER,
  NDS,
  ORGANIZATION,
  PRINCIPAL,
  PROVIDERS,
  TYPE_SERVICE,
  TYPE_UNLOADING,
  COMMENT,
  PROJECT_TYPE,
  TYPE_UNLOADING_SA,
  PRINCIPAL_SA,
  TYPE_SERVICE_SA,
} from '../../bi/constants/account';
import { RANGE_OF_COMPANIES__FOR_1C, REPORT_COMPANIES__FOR_1C } from '../../bi/constants/reports';
import { DATE } from '../../constants/time';

import styles from './styles.module.css';

const DATE_TYPE = 'date';
const OPEN = 'open';

const LABELS = {
  DATE: 'Дата',
  REQUEST: 'Запросить',
  PROJECT: 'Проект',
  UNLOADING: 'Тип выгрузки',
  NDS: 'Ставка НДС',
  ORGANIZATION: 'Организация',
  COMMENT: 'Коментарий',
  COUNTERAGENT: 'Контрагент',
  PRINCIPAL: 'Принципал',
  TYPE_SERVICE: 'Тип услуги',
  DUKEMNT_NUMBER: 'Номер документа',
  PROVIDERS: 'Провайдеры',
  UNLOADING_PERIOD: 'Период выгрузки должен быть в пределах одного месяца',
  RANGE_OF_COMPANIES: 'Диапазон компаний',
  BUTTON_TEXT: 'Автоматическая выгрузка в 1С',
};

const INFO_COMPANIES = {
  NET: 'Net :',
  VIP_SERVICE: 'Вип Сервис :',
  SAVADI: 'Савади :',
  AEROFLOT: 'Аэрофлот :',
  URALSKIE: 'Уральские Авиалинии :',
  VERDEHO: 'Вердехо :',
  AZIMUT: 'Азимут :',
  TKP: 'ТКП :',
  SIBIR: 'Сибирь (S7) :',
  UFC: 'UFC :',
  MY_AGENT: 'Мой Агент :',
  ANGARA: 'Ангара :',
  ALPAKA: 'Альпака :',
  IATA: 'IATA :',
  FILE: 'Файл :',
  SMARTWAY: 'Smartway :',
  TELE_TRAIN: 'TeleTrain :',
  ONELIYA: 'Oneliya :',
  RAVS: 'Ravs :',
  BOOKINGSYSTEM: 'Bookingsystem',
  TOTAL: 'Итого :',
};

class Unloading1c extends Component {
  static propTypes = {
    unloading1cService: PropTypes.object.isRequired,
  };

  state = {
    startDay: prevMonthFirstDay(),
    endDay: prevMonthLastDay(),
    maxDay: getMoment(),

    listCompany: [],

    loadingFileButton: false,
    loadingConclusionButton: false,
    disabledMount: false,
    flagInfoCompany: false,

    selectedProject: null,
    selectedUnloading: null,
    selectedNDS: null,
    selectedOrganization: null,
    selectedComment: {
      id: '',
      type: '',
      value: 0,
      label: '',
    },
    selectedCounteragent: null,
    selectedPrincipal: null,
    selectedTypeService: null,
    selectedDukemntNumber: null,
    selectedRangeOfCompanies: 11,
    selectedProviders: [],
    showDialog: false,
  };

  handleChangeStartDate = (value) => {
    const { endDay } = this.state;

    if (value.valueOf() > endDay.valueOf()) {
      this.setState({
        startDay: value,
        endDay: value,
        disabledMount: false,
      });
    } else {
      this.setState({
        startDay: value,
        disabledMount: !(value.month() === endDay.month()),
      });
    }
  };

  handleChangeEndDate = (value) => {
    const { startDay } = this.state;

    this.setState({
      endDay: value,
      disabledMount: !(value.month() === startDay.month()),
    });
  };

  getPackageDocuments = () => {
    const {
      selectedProject,
      selectedUnloading,
      selectedNDS,
      selectedOrganization,
      selectedComment,
      selectedCounteragent,
      selectedPrincipal,
      selectedTypeService,
      selectedDukemntNumber,
      selectedProviders,
      selectedRangeOfCompanies,
      startDay,
      endDay,
    } = this.state;

    this.setState({ loadingFileButton: true });

    const currentCompanyRange = REPORT_COMPANIES__FOR_1C.find(el => el.value === selectedRangeOfCompanies);

    this.props.unloading1cService.getPackageDocuments({
      startDate: formatDate(startDay, DATE),
      endDate: formatDate(endDay, DATE),
      projectType: selectedProject,
      organization: selectedOrganization,
      comment: selectedComment.label,
      contragent: selectedCounteragent,
      principalType: selectedPrincipal,
      providers: selectedProviders,
      reportType: selectedUnloading,
      vatRate: selectedNDS,
      itemKind: selectedTypeService,
      documentNumber: selectedDukemntNumber,
      companyStart: currentCompanyRange.companyStart,
      companyEnd: currentCompanyRange.companyEnd,
    }).finally(() => {
      this.setState({
        loadingFileButton: false,
      });
    });
  };

  getInfoAmountsSupplier = () => {
    const {
      selectedProject,
      selectedUnloading,
      selectedNDS,
      selectedOrganization,
      selectedComment,
      selectedCounteragent,
      selectedPrincipal,
      selectedTypeService,
      selectedDukemntNumber,
      selectedProviders,
      selectedRangeOfCompanies,
      startDay,
      endDay,
    } = this.state;

    this.setState({ loadingConclusionButton: true });

    const currentCompanyRange = REPORT_COMPANIES__FOR_1C.find(el => el.value === selectedRangeOfCompanies);

    this.props.unloading1cService.getInfoAmountsSupplier({
      startDate: formatDate(startDay, DATE),
      endDate: formatDate(endDay, DATE),
      projectType: selectedProject,
      organization: selectedOrganization,
      comment: selectedComment.label,
      contragent: selectedCounteragent,
      principalType: selectedPrincipal,
      providers: selectedProviders,
      reportType: selectedUnloading,
      vatRate: selectedNDS,
      itemKind: selectedTypeService,
      documentNumber: selectedDukemntNumber,
      companyStart: currentCompanyRange.companyStart,
      companyEnd: currentCompanyRange.companyEnd,
    }).then(() => {
      this.setState({
        listCompany: this.props.unloading1cService.get().listCompany,
        flagInfoCompany: true,
      });
    }).finally(() => {
      this.setState({
        loadingConclusionButton: false,
      });
    });
  }

  getFileXml = (guid) => {
    this.props.unloading1cService.getFileXml(guid);
  }

  handleFilterProject = value => this.setState({
    selectedProject: value.value,
  })

  handleFilterUnloading = value => this.setState({
    selectedUnloading: value.value,
  })

  handleFilterNDS = value => this.setState({
    selectedNDS: value.value,
  })

  handleFilterOrganization = value => this.setState({
    selectedOrganization: value.value,
  })

  handleFilterComment = item => this.setState({
    selectedComment: item,
  })

  handleFilterCounteragent = value => this.setState({
    selectedCounteragent: value.value,
  })

  handleFilterPrincipal = value => this.setState({
    selectedPrincipal: value.value,
  })

  handleFilterTypeService = value => this.setState({
    selectedTypeService: value.value,
  })

  handleFilterDukemntNumber = value => this.setState({
    selectedDukemntNumber: value.value,
  })

  handleFilterRangeOfCompanies = value => this.setState({
    selectedRangeOfCompanies: value.value,
  })

  handleFilterProviders = values => this.setState({
    selectedProviders: values,
  });

  handleOpenDialog = () => this.setState({ showDialog: true });

  handleCloseDialog = () => this.setState({ showDialog: false });

  renderListInfoCompany = () => {
    const { listCompany, flagInfoCompany } = this.state;

    if (!flagInfoCompany) {
      return null;
    }

    return (
      <div className={ `col ${styles.wrapper_cerd_info}` } >
        <div>{INFO_COMPANIES.NET}&ensp;{listCompany.net}</div>
        <p>{INFO_COMPANIES.VIP_SERVICE}&ensp;{listCompany.vipService}</p>
        <p>{INFO_COMPANIES.SAVADI}&ensp;{listCompany.savadi}</p>
        <p>{INFO_COMPANIES.AEROFLOT}&ensp;{listCompany.aeroflot}</p>
        <p>{INFO_COMPANIES.URALSKIE}&ensp;{listCompany.uralskie}</p>
        <p>{INFO_COMPANIES.VERDEHO}&ensp;{listCompany.verdeho}</p>
        <p>{INFO_COMPANIES.AZIMUT}&ensp;{listCompany.azimut}</p>
        <p>{INFO_COMPANIES.TKP}&ensp;{listCompany.tkp}</p>
        <p>{INFO_COMPANIES.SIBIR}&ensp;{listCompany.sibir}</p>
        <p>{INFO_COMPANIES.UFC}&ensp;{listCompany.ufc}</p>
        <p>{INFO_COMPANIES.MY_AGENT}&ensp;{listCompany.myAgent}</p>
        <p>{INFO_COMPANIES.ANGARA}&ensp;{listCompany.angara}</p>
        <p>{INFO_COMPANIES.ALPAKA}&ensp;{listCompany.alpaka}</p>
        <p>{INFO_COMPANIES.IATA}&ensp;{listCompany.iata}</p>
        <p>{INFO_COMPANIES.SMARTWAY}&ensp;{listCompany.smartway}</p>
        <p>{INFO_COMPANIES.TELE_TRAIN}&ensp;{listCompany.teleTrain}</p>
        <p>{INFO_COMPANIES.ONELIYA}&ensp;{listCompany.oneliya}</p>
        <p>{INFO_COMPANIES.RAVS}&ensp;{listCompany.ravs}</p>
        <p>{INFO_COMPANIES.BOOKINGSYSTEM}&ensp;{listCompany.bookingsystem}</p>
        <p>{INFO_COMPANIES.TOTAL}&ensp;{listCompany.total}</p>
        <div className={ styles.wrapper_btn_download }>
          <p>{INFO_COMPANIES.FILE}</p>
          <div className={ styles.btn_download } onClick={ () => this.getFileXml(listCompany.fileLink) }>
            {ACCOUNTTEXT.DOWNLOAD}
          </div>
        </div>
      </div>
    );
  }

  renderDialog = () => {
    const { showDialog } = this.state;

    if (!showDialog) {
      return null;
    }

    return (
      <AutoUnloading1c
        onHandleCloseDialog={ this.handleCloseDialog }
        unloading1cService={ this.props.unloading1cService }
      />
    );
  };

  render() {
    const {
      startDay,
      endDay,
      maxDay,
      loadingFileButton,
      loadingConclusionButton,
      selectedProject,
      selectedUnloading,
      selectedNDS,
      selectedCounteragent,
      selectedPrincipal,
      selectedTypeService,
      selectedDukemntNumber,
      selectedProviders,
      selectedOrganization,
      selectedComment,
      selectedRangeOfCompanies,
      disabledMount,
    } = this.state;

    const tooltipHtml = disabledMount && (
      <Tooltip position={ COMPONENTS.TOOLTIP.POSITION.BOTTOM }>
        <div className={ styles.tooltip }>{LABELS.UNLOADING_PERIOD}</div>
      </Tooltip>
    );

    let typeUnloadingList = TYPE_UNLOADING;
    let principalList = PRINCIPAL;
    let disabledProviders = false;
    let typeServiceList = TYPE_SERVICE;

    if (selectedProject === 1) {
      typeUnloadingList = TYPE_UNLOADING_SA;
      principalList = PRINCIPAL_SA;
      disabledProviders = true;
      typeServiceList = TYPE_SERVICE_SA;
    }

    return (
      <div className={ styles.main }>
        <div className={ styles['render-panel'] }>
          <DatePicker
            placeholder={ LABELS.DATE }
            label={ LABELS.DATE }
            type={ DATE_TYPE }
            inputTheme={ OPEN }
            value={ startDay }
            maxDate={ maxDay }
            onChange={ ({ value }) => this.handleChangeStartDate(value) }
          />
          <DatePicker
            placeholder={ LABELS.DATE }
            label={ LABELS.DATE }
            type={ DATE_TYPE }
            inputTheme={ OPEN }
            value={ endDay }
            maxDate={ maxDay }
            minDate={ startDay }
            onChange={ ({ value }) => this.handleChangeEndDate(value) }
          />
          <Select
            label={ LABELS.PROJECT }
            className={ styles.main_select }
            items={ PROJECT_TYPE }
            value={ selectedProject }
            theme={ COMPONENTS.SELECT.THEME.BORDER }
            onChange={ value => this.handleFilterProject(value) }
          />
          <Select
            label={ LABELS.UNLOADING }
            className={ styles.main_select }
            items={ typeUnloadingList }
            value={ selectedUnloading }
            theme={ COMPONENTS.SELECT.THEME.BORDER }
            onChange={ value => this.handleFilterUnloading(value) }
          />
          <Select
            label={ LABELS.NDS }
            className={ styles.main_select }
            items={ NDS }
            value={ selectedNDS }
            theme={ COMPONENTS.SELECT.THEME.BORDER }
            onChange={ value => this.handleFilterNDS(value) }
          />
          <Select
            label={ LABELS.ORGANIZATION }
            className={ styles.main_select }
            items={ ORGANIZATION }
            value={ selectedOrganization }
            theme={ COMPONENTS.SELECT.THEME.BORDER }
            onChange={ value => this.handleFilterOrganization(value) }
          />
          <SelectWithInput
            placeholder={ LABELS.COMMENT }
            className={ styles.main_select }
            items={ COMMENT }
            currentItem={ selectedComment }
            theme={ COMPONENTS.SELECT.THEME.BORDER }
            typeInput={ COMPONENTS.INPUT.TYPE.TEXT }
            onChange={ this.handleFilterComment }
          />
        </div>
        <div className={ styles['render-panel'] }>
          <Select
            label={ LABELS.COUNTERAGENT }
            className={ styles.main_select }
            items={ COUNTERAGENT }
            value={ selectedCounteragent }
            theme={ COMPONENTS.SELECT.THEME.BORDER }
            onChange={ value => this.handleFilterCounteragent(value) }
          />
          <Select
            label={ LABELS.PRINCIPAL }
            className={ styles.main_select }
            items={ principalList }
            value={ selectedPrincipal }
            theme={ COMPONENTS.SELECT.THEME.BORDER }
            onChange={ value => this.handleFilterPrincipal(value) }
          />
          <Select
            label={ LABELS.TYPE_SERVICE }
            className={ styles.main_select }
            items={ typeServiceList }
            value={ selectedTypeService }
            theme={ COMPONENTS.SELECT.THEME.BORDER }
            onChange={ value => this.handleFilterTypeService(value) }
          />
          <Select
            label={ LABELS.DUKEMNT_NUMBER }
            className={ styles.main_select }
            items={ DUKEMNT_NUMBER }
            value={ selectedDukemntNumber }
            theme={ COMPONENTS.SELECT.THEME.BORDER }
            onChange={ value => this.handleFilterDukemntNumber(value) }
          />
          <Select
            label={ LABELS.RANGE_OF_COMPANIES }
            className={ styles.main_select }
            items={ RANGE_OF_COMPANIES__FOR_1C }
            value={ selectedRangeOfCompanies }
            onChange={ value => this.handleFilterRangeOfCompanies(value) }
            theme={ COMPONENTS.SELECT.THEME.BORDER }
            overflow
          />
          <div className={ styles.main_select }>
            <MultiSelect
              label={ LABELS.PROVIDERS }
              items={ PROVIDERS }
              values={ selectedProviders }
              onChange={ this.handleFilterProviders }
              disabled={ disabledProviders }
              selectAll
            />
          </div>
          <div className={ `${styles['main-button']}  sw-tooltip-wrapper` }>
            { tooltipHtml }
            <AjaxButton
              label={ ACCOUNTTEXT.DOWNLOAD }
              loading={ loadingFileButton }
              disabled={ disabledMount }
              onClick={ () => this.getPackageDocuments() }
            />
          </div>
          <div className={ `${styles['main-button']}  sw-tooltip-wrapper` }>
            { tooltipHtml }
            <AjaxButton
              label={ LABELS.REQUEST }
              loading={ loadingConclusionButton }
              disabled={ disabledMount }
              onClick={ () => this.getInfoAmountsSupplier() }
            />
          </div>
        </div>
        {/* <Button
          onClick={ () => this.handleOpenDialog() }
          label={ LABELS.BUTTON_TEXT }
          className={ styles.button }
        /> */}
        { this.renderListInfoCompany() }
        {/* { this.renderDialog() } */}
      </div>
    );
  }
}

const renderComponents = (box, opts) => {
  const root = createRoot(box);

  root.render(
    <Unloading1c
      appService={ opts.appService }
      goBack={ opts.goBack }
      companyService={ opts.companyService }
      unloading1cService={ opts.unloading1cService }
    />
  );

  return root;
};

export default renderComponents;
