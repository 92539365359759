import renderComponents from './components';

const NAME = 'sw-trip';

class TripModule {
  constructor(name, context, opts) {
    this.name = name;
    this.box = opts.box;
    this.context = context;

    const accountService = this.context.getService('Account');
    const appService = context.getService('App');
    const airService = context.getService('Air');
    const aeroexpressService = context.getService('Aeroexpress');
    const tripService = context.getService('Trip');
    const guidService = context.getService('Guid');
    const travelPolicyService = context.getService('TravelPolicy');
    const notificationsService = context.getService('Notifications');
    const featureFlagsService = this.context.getService('FeatureFlags');
    const companyService = this.context.getService('Company');

    this.page = renderComponents(this.box, {
      tripService,
      guidService,
      appService,
      airService,
      aeroexpressService,
      travelPolicyService,
      notificationsService,
      featureFlagsService,
      companyService,
      accountService,
      goBack: this.goBack,
      moveToTrip: this.moveToTrip,
      moveToCompany: this.moveToCompany,
    });
  }

  destroy() {
    this.page.unmount();
  }
  goBack = () => {
    history.back();
  };

  moveToCompany = (accountId, companyId) => {
    const path = `/account/${accountId}/company/${companyId}/account/profile`;

    this.context.navigate(path);
  };

  moveToTrip = (companyId, tripId) => {
    const { accountId } = this.context.getService('App').get().params;
    const path = `/account/${accountId}/company/${companyId}/trip/${tripId}`;

    this.context.navigate(path);
  };
}

export default function (core) {
  core.addModule(NAME, TripModule);
}
