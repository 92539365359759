import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'sw-ui';

import TextArea from '../../../../components/textarea';
import { BackButton } from './BackButton';
import { NextButton } from './NextButton';

import COMPONENTS from '../../../../bi/constants/components';
import {
  GROUPING_TYPE,
  DAY_TYPE,
  AMOUNT_TYPE,
  NOMENCLATURE_VAT_RATE,
} from '../../../../bi/constants/account';

import styles from '../../styles.module.css';

const LABELS = {
  UNIFICATION: 'Объединение реализаций',
  DATE: 'Дата реализации',
  AMOUNT: 'Сумма реализации (из Excel) по столбцу',
  NOMENCLATURE: 'Номенклатура',
  COMMENT: 'Комментарий',
  WRITE_COMMENT: 'Напишите комментарий',
  LIMIT: 'Ограничение 1000 символов',
};

const LIMIT = 1000;

const StepThree = ({
  unloading1cService: {
    get,
    setGroupingType,
    setDayType,
    setAmountType,
    setNomenclature,
    setComment,
  },
  onHandleNextStep,
  onHandlePrevStep,
}) => {
  const {
    realizationSettings: {
      groupingType,
      dayType,
    },
    fileImportSettings: {
      excelSettings: {
        columnsVatRates,
      },
    },
    nomenclatureVatRate,
  } = get().settings;

  const initState = Object.keys(columnsVatRates).join(', ');

  const [selectedUnification, setSelectedUnification] = useState(groupingType);
  const [selectedDate, setSelectedDate] = useState(dayType);
  const [selectedAmount, setSelectedAmount] = useState(initState);
  const [selectedNomenclature, setSelectedNomenclature] = useState(nomenclatureVatRate);
  const [textareaValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const isDisabled = (
    selectedUnification === null
    || selectedDate === null
    || !selectedAmount
    || (selectedAmount !== AMOUNT_TYPE[2].value && selectedNomenclature === null)
  );

  const handleSelectUnification = ({ value }) => {
    setSelectedUnification(value);
    setGroupingType(value);
  };

  const handleSelectDate = ({ value }) => {
    setSelectedDate(value);
    setDayType(value);
  };

  const handleSelectAmount = ({ value }) => {
    setSelectedAmount(value);
    setAmountType(value);
    setSelectedNomenclature(null);
  };

  const handleSelectNomenclature = ({ value }) => {
    setSelectedNomenclature(value);
    setNomenclature(value);
  };

  const handleChangeTextArea = (value) => {
    if (value.length > LIMIT) {
      return setErrorMessage(LABELS.LIMIT);
    }

    setErrorMessage('');
    setInputValue(value);
  };

  const handleNextStep = () => {
    setComment(textareaValue);
    onHandleNextStep();
  };

  const renderNomenclature = () => {
    if (selectedAmount === AMOUNT_TYPE[2].value || !selectedAmount) {
      return null;
    }

    return (
      <React.Fragment>
        <div className={ styles.title }>
          { LABELS.NOMENCLATURE }
        </div>
        <Select
          label={ LABELS.NOMENCLATURE }
          items={ NOMENCLATURE_VAT_RATE }
          value={ selectedNomenclature }
          theme={ COMPONENTS.SELECT.THEME.BORDER }
          onChange={ value => handleSelectNomenclature(value) }
        />
      </React.Fragment>
    );
  };

  return (
    <div className={ styles.content }>
      <div className={ styles.title }>
        { LABELS.UNIFICATION }
      </div>
      <Select
        label={ LABELS.UNIFICATION }
        items={ GROUPING_TYPE }
        value={ selectedUnification }
        theme={ COMPONENTS.SELECT.THEME.BORDER }
        onChange={ value => handleSelectUnification(value) }
      />
      <div className={ styles.title }>
        { LABELS.DATE }
      </div>
      <Select
        label={ LABELS.DATE }
        items={ DAY_TYPE }
        value={ selectedDate }
        theme={ COMPONENTS.SELECT.THEME.BORDER }
        onChange={ value => handleSelectDate(value) }
      />
      <div className={ styles.title }>
        { LABELS.AMOUNT }
      </div>
      <Select
        label={ LABELS.AMOUNT }
        items={ AMOUNT_TYPE }
        value={ selectedAmount }
        theme={ COMPONENTS.SELECT.THEME.BORDER }
        onChange={ value => handleSelectAmount(value) }
      />
      { renderNomenclature() }
      <div className={ styles.title }>
        { LABELS.COMMENT }
      </div>
      <TextArea
        placeholder={ LABELS.WRITE_COMMENT }
        value={ textareaValue }
        onChange={ handleChangeTextArea }
        valid={ errorMessage }
        className={ styles.width_100 }
      />
      <div className={ styles.modal_buttons }>
        <BackButton
          onHandlePrevStep={ onHandlePrevStep }
        />
        <NextButton
          onHandleNextStep={ handleNextStep }
          disabled={ isDisabled }
        />
      </div>
    </div>
  );
};

StepThree.propTypes = {
  unloading1cService: PropTypes.object.isRequired,
  onHandleNextStep: PropTypes.func.isRequired,
  onHandlePrevStep: PropTypes.func.isRequired,
};

export { StepThree };
