import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'sw-ui';

import { StepOne } from './components/StepOne';
import { StepTwo } from './components/StepTwo';
import { StepThree } from './components/StepThree';
import { StepFour } from './components/StepFour';
import { StepFive } from './components/StepFive';

import { NUMBERS } from '../../../bi/constants/unloading1c';

import styles from '../styles.module.css';

const WIDTH = {
  DEFAULT: '400px',
};

const AutoUnloading1c = ({
  onHandleCloseDialog,
  unloading1cService,
  unloading1cService: {
    get,
    setStep,
    resetStore,
  },
}) => {
  const { step, settings: { reportType } } = get();

  const [currentStep, setCurrentStep] = useState(step);

  const handleNextStep = () => {
    if (currentStep < NUMBERS.SIX) {
      setCurrentStep(currentStep + 1);
      setStep(currentStep + 1);
    }
  };

  const handlePrevStep = () => {
    if (currentStep > NUMBERS.ZERO) {
      setCurrentStep(currentStep - 1);
      setStep(currentStep - 1);
    }
  };

  const handleStepOne = () => {
    setCurrentStep(NUMBERS.ONE);
    setStep(NUMBERS.ONE);
    resetStore();
  };

  const renderStep = () => {
    const props = {
      unloading1cService,
      currentStep,
      onHandleNextStep: handleNextStep,
      onHandlePrevStep: handlePrevStep,
      onHandleStepOne: handleStepOne,
    };

    switch (currentStep) {
      case NUMBERS.ONE:
        return <StepOne { ...props } />;
      case NUMBERS.TWO:
        return <StepTwo { ...props } />;
      case NUMBERS.THREE:
        return <StepThree { ...props } />;
      case NUMBERS.FOUR:
        if (reportType === NUMBERS.FIVE || reportType === NUMBERS.EIGHT) {
          return <StepFive { ...props } />;
        }

        return <StepFour { ...props } />;
      case NUMBERS.FIVE:
        return <StepFive { ...props } />;
      default:
        return null;
    }
  };

  return (
    <Dialog
      onClick={ onHandleCloseDialog }
      showCloseButton
      width={ WIDTH.DEFAULT }
    >
      <div className={ styles.modal_wrap }>
        <div className={ styles.modal_body }>
          { renderStep() }
        </div>
      </div>
    </Dialog>
  );
};

AutoUnloading1c.propTypes = {
  onHandleCloseDialog: PropTypes.func.isRequired,
  unloading1cService: PropTypes.object.isRequired,
};

export { AutoUnloading1c };
