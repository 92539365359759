import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RadioButton, Input } from 'sw-ui';

import { BackButton } from './BackButton';
import { NextButton } from './NextButton';

import { REPORT_TYPE } from '../../../../bi/constants/account';
import { NUMBERS } from '../../../../bi/constants/unloading1c';

import styles from '../../styles.module.css';

const LABELS = {
  UNLOADING: 'Тип выгрузки',
  LINK_DOCUMENT: 'Введите ссылку на документ',
  ERROR: 'Поле не может быть пустым',
};

const StepOne = ({
  unloading1cService: {
    get,
    setReportType,
    getWorkSheets,
    setError,
    setFileLink,
  },
  currentStep,
  onHandleNextStep,
  onHandlePrevStep,
}) => {
  const { reportType } = get().settings;
  const initState = REPORT_TYPE.find(({ value }) => value === reportType);

  const [type, setUnloadingType] = useState(initState ? initState.label : null);
  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const isDisabled = !type || loading;

  const handleChangeRadio = (radioValue) => {
    setUnloadingType(radioValue);

    const { value } = REPORT_TYPE.find(({ label }) => label === radioValue);

    setReportType(value);
  };

  const handleChangeInput = value => setInputValue(value);

  const handleRequest = async () => {
    setError('');

    if (!inputValue) {
      return setErrorMessage(LABELS.ERROR);
    }

    setLoading(true);
    await getWorkSheets({ FileLink: inputValue });

    const { error } = get();

    if (error) {
      setInputValue('');
      setLoading(false);
      setErrorMessage(error);
      return;
    }

    setFileLink(inputValue);
    setErrorMessage('');
    setLoading(false);
    onHandleNextStep();
  };

  const renderRadio = () => (
    REPORT_TYPE.map(({ label, value }) => (
      <RadioButton
        key={ value }
        onChange={ () => handleChangeRadio(label) }
        checked={ type === label }
      >
        { label }
      </RadioButton>
    ))
  );

  const renderBackButton = () => {
    if (currentStep === NUMBERS.ONE) {
      return null;
    }

    return (
      <BackButton
        onHandlePrevStep={ onHandlePrevStep }
      />
    );
  };

  return (
    <div className={ styles.content }>
      <div className={ styles.title }>
        { LABELS.UNLOADING }
      </div>
      <div className={ styles.radio }>
        { renderRadio() }
      </div>
      <Input
        placeholder={ LABELS.LINK_DOCUMENT }
        value={ inputValue }
        onChange={ handleChangeInput }
        error={ errorMessage }
      />
      <div className={ styles.modal_buttons }>
        { renderBackButton() }
        <NextButton
          onHandleNextStep={ handleRequest }
          loading={ loading }
          disabled={ isDisabled }
        />
      </div>
    </div>
  );
};

StepOne.propTypes = {
  unloading1cService: PropTypes.object.isRequired,
  currentStep: PropTypes.number.isRequired,
  onHandleNextStep: PropTypes.func.isRequired,
  onHandlePrevStep: PropTypes.func.isRequired,
};

export { StepOne };
