import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Select, Input } from 'sw-ui';

import { BackButton } from './BackButton';
import { NextButton } from './NextButton';

import COMPONENTS from '../../../../bi/constants/components';
import { NUMBERS, SOURCE_TYPE, EXCEL } from '../../../../bi/constants/unloading1c';

import styles from '../../styles.module.css';

const LABELS = {
  SELECT_TAB: 'Выберите вкладку',
  ALL_TABS: 'Все вкладки',
  PRINCIPAL: 'Принципал',
  COLUMN_NAME: 'Название столбца',
  PLACEHOLDER: 'Введите название столбца с номером билета',
  SEND_XML: 'Отправить XML на email',
  LOAD_FILE_EXCEL: 'Загрузить другой файл Excel',
};

const StepFive = ({
  unloading1cService: {
    get,
    setSheet,
    setPrincipal,
    sendSettings,
    setError,
    setTripItemColumnName,
  },
  onHandlePrevStep,
  onHandleStepOne,
}) => {
  const { workSheets, principals, settings, error } = get();
  const {
    reportType,
    fileImportSettings: { excelSettings: { sheets, tripItemColumnName } },
    providerSourceType,
    principalInfoId,
  } = settings;

  const WORK_SHEETS = workSheets.map(sheet => ({ label: sheet, value: sheet }));
  const ALL_WORK_SHEETS = [...WORK_SHEETS, { label: LABELS.ALL_TABS, value: LABELS.ALL_TABS }];
  const ALL_PRINCIPALS = principals.map(({ Name, Inn, Id }) => ({ label: `${Name} ИНН: ${Inn}`, value: Id }));
  const initStateSheet = sheets.length <= NUMBERS.ONE ? sheets.join('') : LABELS.ALL_TABS;
  const initStateInputValue = tripItemColumnName === SOURCE_TYPE.TRIP_ITEM_ID ? '' : tripItemColumnName;

  const [currentSheet, setCurrentSheet] = useState(initStateSheet);
  const [currentPrincipal, setCurrentPrincipal] = useState(principalInfoId);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(initStateInputValue);

  const isDisabled = !currentSheet || currentPrincipal === null || loading;

  const handleSelectSheet = ({ value }) => {
    setCurrentSheet(value);

    if (value === LABELS.ALL_TABS) {
      return setSheet(workSheets);
    }

    setSheet([value]);
  };

  const handleSelectPrincipal = ({ value }) => {
    setCurrentPrincipal(value);
    setPrincipal(value);
  };

  const handleRequest = async () => {
    setError('');
    setLoading(true);
    await sendSettings(settings);
    setLoading(false);
  };

  const handleChangeInput = (value) => {
    setInputValue(value);
    setTripItemColumnName(value);
  };

  const renderSelectPrincipal = () => {
    if (reportType === NUMBERS.FIVE || reportType === NUMBERS.EIGHT || providerSourceType === EXCEL) {
      return null;
    }

    return (
      <React.Fragment>
        <div className={ styles.title }>
          { LABELS.PRINCIPAL }
        </div>
        <Select
          label={ LABELS.PRINCIPAL }
          items={ ALL_PRINCIPALS }
          value={ currentPrincipal }
          theme={ COMPONENTS.SELECT.THEME.BORDER }
          onChange={ value => handleSelectPrincipal(value) }
        />
      </React.Fragment>
    );
  };

  const renderError = () => {
    if (!error) {
      return null;
    }

    return (
      <span className={ 'error-msg' }>
        { error }
      </span>
    );
  };

  return (
    <div className={ styles.content }>
      <div className={ styles.title }>
        { LABELS.SELECT_TAB }
      </div>
      <Select
        label={ LABELS.SELECT_TAB }
        items={ ALL_WORK_SHEETS }
        value={ currentSheet }
        theme={ COMPONENTS.SELECT.THEME.BORDER }
        onChange={ value => handleSelectSheet(value) }
      />
      { renderSelectPrincipal() }
      <div className={ styles.title }>
        { LABELS.COLUMN_NAME }
      </div>
      <Input
        placeholder={ LABELS.PLACEHOLDER }
        value={ inputValue }
        onChange={ handleChangeInput }
      />
      <div className={ `${styles.modal_buttons} ${styles.last_step}` }>
        <BackButton
          onHandlePrevStep={ onHandlePrevStep }
        />
        <NextButton
          onHandleNextStep={ handleRequest }
          loading={ loading }
          disabled={ isDisabled }
          label={ LABELS.SEND_XML }
        />
        { renderError() }
        <NextButton
          onHandleNextStep={ onHandleStepOne }
          label={ LABELS.LOAD_FILE_EXCEL }
        />
      </div>
    </div>
  );
};

StepFive.propTypes = {
  unloading1cService: PropTypes.object.isRequired,
  onHandlePrevStep: PropTypes.func.isRequired,
  onHandleStepOne: PropTypes.func.isRequired,
};

export { StepFive };
