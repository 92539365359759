import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'sw-ui';

import COMPONENTS from '../../../../bi/constants/components';

const LABELS = {
  BACK: 'Назад',
};

const BackButton = ({
  onHandlePrevStep,
}) => (
  <Button
    onClick={ onHandlePrevStep }
    theme={ COMPONENTS.BUTTON.THEME.SECOND }
    label={ LABELS.BACK }
  />
);

BackButton.propTypes = {
  onHandlePrevStep: PropTypes.func.isRequired,
};

export { BackButton };
