import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Select, Tooltip } from 'sw-ui';

import { BackButton } from './BackButton';
import { NextButton } from './NextButton';

import { getMoment, prevMonthFirstDay, prevMonthLastDay, momentObject } from '../../../../bi/utils/formatDate';
import { formatDate } from '../../../../utils/date';

import COMPONENTS from '../../../../bi/constants/components';
import { DATE } from '../../../../constants/time';
import {
  COUNTERAGENT,
  ORGANIZATIONS,
  TYPE_SERVICE_SA,
} from '../../../../bi/constants/account';

import styles from '../../styles.module.css';

const LABELS = {
  PERIOD: 'Период выгрузки',
  FROM: 'С',
  TO: 'По',
  COMPANY: 'Выберите компанию',
  COUNTERAGENT: 'Контрагент',
  TYPE_IMPLEMENTATION: 'Тип реализации',
  UNLOADING_PERIOD: 'Период выгрузки должен быть в пределах одного месяца',
};

const DATE_TYPE = 'date';
const OPEN = 'open';

const StepTwo = ({
  unloading1cService: {
    setStartDate,
    setEndDate,
    setCompany,
    setCounteragent,
    setTypeImplementation,
    setDisabledMonth,
    get,
  },
  onHandleNextStep,
  onHandlePrevStep,
}) => {
  const {
    startDate,
    endDate,
    organizationType,
    counteragentType,
    realizationSettings: {
      ownerType,
    },
  } = get().settings;

  const { disabledMonth } = get();

  const [startDay, setStartDay] = useState(startDate || prevMonthFirstDay());
  const [endDay, setEndDay] = useState(endDate || prevMonthLastDay());
  const [isDisabledMonth, setDisabled] = useState(disabledMonth);
  const [selectedCompany, setSelectedCompany] = useState(organizationType);
  const [selectedCounteragent, setSelectedCounteragent] = useState(counteragentType);
  const [selectedTypeImplementation, setSelectedTypeImplementation] = useState(ownerType);

  const maxDay = getMoment();
  const isDisabled = (
    isDisabledMonth
    || selectedCompany === null
    || selectedCounteragent === null
    || selectedTypeImplementation === null
  );

  const handleChangeStartDate = (value) => {
    const correctDate = formatDate(value, DATE);
    const isDisable = value.month() !== momentObject(endDay).month();

    if (value.valueOf() > endDay.valueOf()) {
      setStartDay(value);
      setEndDay(value);
      setDisabled(false);

      setDisabledMonth(false);
      setStartDate(correctDate);
      setEndDate(correctDate);
    } else {
      setStartDay(value);
      setDisabled(isDisable);

      setDisabledMonth(isDisable);
      setStartDate(correctDate);
    }
  };

  const handleChangeEndDate = (value) => {
    const correctDate = formatDate(value, DATE);
    const isDisable = value.month() !== momentObject(startDay).month();

    setEndDay(value);
    setDisabled(isDisable);

    setEndDate(correctDate);
    setDisabledMonth(isDisable);
  };

  const handleSelectCompany = ({ value }) => {
    setSelectedCompany(value);
    setCompany(value);
  };

  const handleSelectCounteragent = ({ value }) => {
    setSelectedCounteragent(value);
    setCounteragent(value);
  };

  const handleSelectTypeImplementation = ({ value }) => {
    setSelectedTypeImplementation(value);
    setTypeImplementation(value);
  };

  const handleNextStep = () => {
    setStartDate(formatDate(startDay, DATE));
    setEndDate(formatDate(endDay, DATE));
    onHandleNextStep();
  };

  const renderTooltip = () => {
    if (!isDisabledMonth) {
      return null;
    }

    return (
      <Tooltip position={ COMPONENTS.TOOLTIP.POSITION.BOTTOM }>
        <div className={ styles.tooltip }>{ LABELS.UNLOADING_PERIOD }</div>
      </Tooltip>
    );
  };

  return (
    <div className={ styles.content }>
      <div className={ styles.title }>
        { LABELS.PERIOD }
      </div>
      <div className={ styles.datepicker }>
        <DatePicker
          placeholder={ LABELS.FROM }
          label={ LABELS.FROM }
          type={ DATE_TYPE }
          inputTheme={ OPEN }
          value={ startDay }
          maxDate={ maxDay }
          onChange={ ({ value }) => handleChangeStartDate(value) }
        />
        <DatePicker
          placeholder={ LABELS.TO }
          label={ LABELS.TO }
          type={ DATE_TYPE }
          inputTheme={ OPEN }
          value={ endDay }
          maxDate={ maxDay }
          minDate={ startDay }
          onChange={ ({ value }) => handleChangeEndDate(value) }
        />
      </div>
      <div className={ styles.title }>
        { LABELS.COMPANY }
      </div>
      <Select
        label={ LABELS.COMPANY }
        items={ ORGANIZATIONS }
        value={ selectedCompany }
        theme={ COMPONENTS.SELECT.THEME.BORDER }
        onChange={ value => handleSelectCompany(value) }
      />
      <div className={ styles.title }>
        { LABELS.COUNTERAGENT }
      </div>
      <Select
        label={ LABELS.COUNTERAGENT }
        items={ COUNTERAGENT }
        value={ selectedCounteragent }
        theme={ COMPONENTS.SELECT.THEME.BORDER }
        onChange={ value => handleSelectCounteragent(value) }
      />
      <div className={ styles.title }>
        { LABELS.TYPE_IMPLEMENTATION }
      </div>
      <Select
        label={ LABELS.TYPE_IMPLEMENTATION }
        items={ TYPE_SERVICE_SA }
        value={ selectedTypeImplementation }
        theme={ COMPONENTS.SELECT.THEME.BORDER }
        onChange={ value => handleSelectTypeImplementation(value) }
      />
      <div className={ styles.modal_buttons }>
        <BackButton
          onHandlePrevStep={ onHandlePrevStep }
        />
        <div className={ `sw-tooltip-wrapper ${styles.width_100}` }>
          <NextButton
            onHandleNextStep={ handleNextStep }
            disabled={ isDisabled }
          />
          { renderTooltip() }
        </div>
      </div>
    </div>
  );
};

StepTwo.propTypes = {
  unloading1cService: PropTypes.object.isRequired,
  onHandleNextStep: PropTypes.func.isRequired,
  onHandlePrevStep: PropTypes.func.isRequired,
};

export { StepTwo };
