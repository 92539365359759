import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RadioButton } from 'sw-ui';

import { BackButton } from './BackButton';
import { NextButton } from './NextButton';

import { ALL_PROVIDERS } from '../../../../bi/constants/account';

import styles from '../../styles.module.css';

const LABELS = {
  SELECT_PROVIDERS: 'Выберите провайдера',
};

const StepFour = ({
  unloading1cService: {
    get,
    setProvider,
    getPrincipals,
    setError,
  },
  onHandleNextStep,
  onHandlePrevStep,
}) => {
  const { provider } = get();
  const savedProvider = ALL_PROVIDERS.find(({ value }) => value === provider);
  const initState = savedProvider ? savedProvider.label : null;

  const [currentProvider, setCurrentProvider] = useState(initState);
  const [loading, setLoading] = useState(false);

  const isDisabled = !currentProvider || loading;

  const handleChangeRadio = (radioValue) => {
    setCurrentProvider(radioValue);

    const { value } = ALL_PROVIDERS.find(({ label }) => label === radioValue);

    setProvider(value);
  };

  const handleRequest = async () => {
    setError('');
    setLoading(true);

    const { value } = ALL_PROVIDERS.find(({ label }) => label === currentProvider);
    await getPrincipals(value);

    const { error } = get();

    if (error) {
      setLoading(false);
      return;
    }

    setLoading(false);
    onHandleNextStep();
  };

  const renderRadio = () => (
    ALL_PROVIDERS.map(({ label, value }) => (
      <RadioButton
        key={ value }
        onChange={ () => handleChangeRadio(label) }
        checked={ currentProvider === label }
      >
        { label }
      </RadioButton>
    ))
  );

  return (
    <div className={ styles.content }>
      <div className={ styles.title }>
        { LABELS.SELECT_PROVIDERS }
      </div>
      <div className={ styles.radio }>
        { renderRadio() }
      </div>
      <div className={ styles.modal_buttons }>
        <BackButton
          onHandlePrevStep={ onHandlePrevStep }
        />
        <NextButton
          onHandleNextStep={ handleRequest }
          loading={ loading }
          disabled={ isDisabled }
        />
      </div>
    </div>
  );
};

StepFour.propTypes = {
  unloading1cService: PropTypes.object.isRequired,
  onHandleNextStep: PropTypes.func.isRequired,
  onHandlePrevStep: PropTypes.func.isRequired,
};

export { StepFour };
